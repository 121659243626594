import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

//model
import { Productbarcode } from '../models/productbarcode';
import { Invoicedet } from '../models/invoicedet';
import { Tienda } from '../models/tienda';
import { ListaPair } from '../models/listapair';

//service
import { LoginService } from '../common/services/login.service';
import { ProductService } from '../common/services/product.service';

//toastr
import { ToastrService } from 'ngx-toastr';

//Dialog
import { CatalogComponent } from '../catalog/catalog.component';

import { MyDialogConsultaClienteComponent } from '../my-dialog-consulta-cliente/my-dialog-consulta-cliente.component';


declare var jQuery: any;
declare var $: any;

export interface Food2 {
  value: string;
  viewValue: string;
}

export interface Cliente {
  codcli: string;
  nomcom: string;
}

export interface PagoMonto {
  codtab: string;
  destab: string;
}


@Component({
  selector: 'app-ingegre',
  templateUrl: './ingegre.component.html',
  styleUrls: ['./ingegre.component.css'],
  providers: [LoginService]
})
export class IngegreComponent {

  constructor(
    private _loginService: LoginService,
    private _productService: ProductService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private catalogComponent: CatalogComponent,
  ) {

  }

  dataDef = [];
  url3: string = "";
  motivoIngEgreList: ListaPair[];
  selectedTipoIngEgreValue: string= "";
  selectedMotivoIngEgreValue: string= "";
  monto: number = 0.000;
  observ: string = "";
  
  
  invoiceCab = [];
  
  
  loading = false;
  
  
  clienteListCombo = [];

  

  sucursal: string = "";
  identity: any;
  //selectedCodcliValue: string;
  

  
  clientes: Cliente[];
  
  ngOnInit() {

    console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");

    this.dataDef = this._loginService.getDataDef();
    this.verMotivoIngEgreCombo();
    
    this.url3 = this._loginService.url3;
    
    
    this.identity = this._loginService.getIdentity();
    //this.invoiceCab = [{'codcli':'5','coddir':'0001','codper':'44001713','nomcom':'otros mas'}];
    this.invoiceCab = this._loginService.getDataDef();
    console.log("entra a oninit de SHOP ");
    console.log("this.identity: " + this.identity);
    console.log("this.invoiceCab: " + this.invoiceCab);
    console.log(this._loginService.getDataDef());
    this.sucursal = this.invoiceCab['sucursal'];
    //this.selectedCodcliValue = this.invoiceCab['codcli'];


    if (this.sucursal == null) {
      this.sucursal = "";
    }

  }

  

  crearTicket(): void {    
    console.log("Ing/Egre:" + this.selectedTipoIngEgreValue)
    console.log("Motivo:" + this.selectedMotivoIngEgreValue)
    console.log("Monto:" + this.monto)
    console.log("Observacion:" + this.observ)

    

    console.log("Inicio creando nuevo ticket");


    this.loading = true;

    this.observ = this.observ.trim();
    this.invoiceCab["sucursal"] = this.sucursal;
    this.invoiceCab['tipoingegre'] = this.selectedTipoIngEgreValue;
    this.invoiceCab['motivo'] = this.selectedMotivoIngEgreValue;
    this.invoiceCab['monto'] = this.monto;
    this.invoiceCab['observ'] = this.observ;    
    
    //this.invoiceCab["codcli"] = this.selectedCodcliValue;

    console.log(this.invoiceCab);    

    if (this.invoiceCab['sucursal'] == "") {
      this.toastr.error('Falta seleccionar Sucursal');
      this.loading = false;
      return
    }


    if (this.invoiceCab['tipoingegre'] != "1" && this.invoiceCab['tipoingegre'] != "2") {
      this.toastr.error('Falta seleccionar Ingreso o Salida');
      this.loading = false;
      return
    }

    if (this.invoiceCab['codcli'] == "") {
      this.toastr.error('Falta seleccionar Cliente');
      this.loading = false;
      return
    }

    if (this.invoiceCab['motivo'] == "") {
      this.toastr.error('Falta seleccionar Motivo');
      this.loading = false;
      return
    }

    if ((this.invoiceCab['monto'] * 1) <= 0) {
      this.toastr.error('Falta ingresar monto');
      this.loading = false;
      return
    }

    if (this.invoiceCab['observ'] == "") {
      this.toastr.error('Falta ingresar Observaciones');
      this.loading = false;
      return
    }

    
    
    
    ///////////////
    //this.toastr.success('Validacion F I N A L   T O T A L OK');    
    //this.loading = false;
    //return;
    ///////////////



    this._loginService.crearIngEgrePos(this.invoiceCab).subscribe(
      response => {
        console.log(response);
        console.log("cargar link");

        if (response.r1 == 0) {
          
          this.toastr.success('Nuevo registro añadido exitosamente', 'Ingreso / Egreso');          
          
          this.invoiceCab = this._loginService.getDataDef();
          
          this.catalogComponent.loadIngEgre('');
          
          this.selectedTipoIngEgreValue = "";
          this.selectedMotivoIngEgreValue = "";
          this.monto = 0.00;
          this.observ = "";

        } else {
          this.toastr.error(response.r4, 'Sistema');
        }

        //finaliza

        //this.invoiceCab = this._loginService.getDataDef();
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.toastr.error("Ha ocurrido un error", 'Sistema');
        console.log("ERROR ERROR ERROR ERROR ERROR ERROR ERROR .");

        //console.log(<any>error);

        var errorMessage = <any>error;
        if (errorMessage != null) {
          var body = JSON.parse(errorMessage._body);

        }

        //this.toastr.error(errorMessage, 'Venta');
      })

  }

  

  openDialogConsultaCliente(): void {

    let dialogRefDes = this.dialog.open(MyDialogConsultaClienteComponent, {
      width: '600px',
      // data: { name: this.name, animal: this.animal }
      //data: ''      
    });

    dialogRefDes.afterClosed().subscribe(result => {
      //alert(result);
      if (result != "" && result != undefined) {
        //alert("aaaa");
        console.log(result);
        //console.log(this.invoiceCab);
        this.invoiceCab['inden'] = result.inden;
        this.invoiceCab['desinden'] = result.desinden;
        this.invoiceCab['codcli'] = result.codcli;
        this.invoiceCab['nomcom'] = result.nomcom;
        this.invoiceCab['dircli'] = result.dircli;

      }
      //console.log('The dialog was closed');
      //this.animal = result;
    });
  }

  
 
  verMotivoIngEgreCombo(): void {

    this._loginService.motivoIngEgreCombo().subscribe(
      response => {
        console.log("L I S T A   D E   MOTIVO INICIO COMBO");
        console.log(response);        
        this.motivoIngEgreList = response.data;  
        console.log(this.motivoIngEgreList);
        console.log("L I S T A   D E   MOTIVO FIN COMBO");
      },
      error => {
        console.log(<any>error);
        //console.log("error 454545.");
        var errorMessage = <any>error;
        if (errorMessage != null) {
          var body = JSON.parse(error._body);          
        }
      }
    )
  }

}